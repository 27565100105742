<template>
	<!-- 徽章记录 -->
	<div class="badgeRecord">
		<!-- 家长端徽章记录 -->
			<div class="" style="height: 100%;display: flex;flex-direction: column;">
				<div class="top">
					<div class="content">
						<div class="my-card" v-for="(item,index) in  List" :key="index" @click="badgeClick(index)"
							:class="[currentIndex == index? 'active':'']">
							<img :src="urlList[item.BadgeRecordType - 1].url" />
						</div>
					</div>
				</div>
				<div class="middle" style="flex: 1;background-color: white;">
						<div class="content" v-if="List.length >0">
							<div class="" >
								<van-tag round size="large" color="#47AFA7" text-color="#000000" style="padding: 6px 20px;font-size: 18px;">
									<span>{{bagIndex}}</span>
									
								</van-tag>
							</div>
							<div class="title">
								{{titleList[currentIndex]}}
							</div>

							<div class="textArea" style="padding: 10px 0px;color: #00aaff;border-bottom: 1px solid #ededed;;
							border-top: 1px solid #ededed;margin-top: 20px;">
								<p>{{EvaluateContent}}</p>
							</div>
							<!-- 创建人 -->
							<div class="" style="margin-top: 20px;">
								<span style="color: #a6a6a6;">
								   <van-icon name="bookmark-o" />
									来自
								</span>
								<span>{{realName}}</span>
							</div>
							<!-- 创建时间-->
							<div class="" style="margin-top: 10px;color: #a6a6a6;">
								<van-icon name="underway-o" />
								{{createDate}}
							</div>
						</div>
						<van-empty description="暂无数据" v-else />
				</div>
			</div>
	</div>
</template>

<script>
	import { json } from "body-parser";
import {
		Form,
		Field,
		Button,
		Popup,
		Toast,
		ActionSheet,
		Tag,
		Icon,
		Empty
	} from "vant";

	export default {
		name: 'parent',
		components: {
			[Toast.name]: Toast,
			[ActionSheet.name]: ActionSheet,
			[Form.name]: Form,
			[Button.name]: Button,
			[Field.name]: Field,
			[Tag.name]: Tag,
			[Icon.name]: Icon,
			[Empty.name]: Empty
		},
		computed: {
			bagIndex() {
				return this.List[this.currentIndex]?.Index + '/' + this.count
			},
			createDate() {
				return this.List[this.currentIndex]?.CreateDate
			},
			realName() {
				return this.List[this.currentIndex]?.RealName
			},
			EvaluateContent(){
				return this.List[this.currentIndex]?.EvaluateContent
			}
		},	
		data() {
			return {
				userType: window.localStorage.getItem("UserType"),
				//childrenID:JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren.ID,
				List: [],
				currentIndex: 0, //
				//show: true,
				titleList: ['文明小宝贝', '小小艺术家', '小小智多星', '语言小天才', '运动小达人'],
				form: {
					EvaluateContent: ''
				},
				loading: false,
				urlList: [{
						url: require('../../../../../assets/home/one.png')
					},
					{
						url: require('../../../../../assets/home/two.png')
					},
					{
						url: require('../../../../../assets/home/three.png')
					},
					{
						url: require('../../../../../assets/home/four.png')
					},
					{
						url: require('../../../../../assets/home/five.png')
					}
				], //类型对应图片
				count: 0, //总数
				childrenID:'',
			}
		},
		methods: {
			// 徽章点击
			badgeClick(index) {
				console.log(index)
				this.currentIndex = index
				//this.show = true
			}, 
			// 获取徽章列表
			_getBadgeList() {
				
				let childrenID=JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren?.ID
				this.$axios.get('/api/BadgeRecord/badgeRecord-list', {
					childrenID: childrenID?childrenID:this.childrenID
				}).then(res => {
					this.List = res.data
					this.count = res.count
				})
			},
			cancel() {
				//this.show = false
				this.form.EvaluateContent = ''
			}
		}
	}
</script>

<style lang="less" scoped>
	.badgeRecord {
		height: 100%;

		.top {
			height: 22%;
			background-image: linear-gradient(#47AFA7, #ffffff);
			display: flex;

			.content {
				display: flex;
				overflow-x: scroll;
				align-items: center;
				.my-card {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 10px;
					padding: 10px 6px;

					img {
						width: 70px;
						height: 70px;
					}
				}

				.active {
					scale: 1.3;
					margin: 0 20px;
				}
			}
		}

		.middle {
			.content {
				padding: 20px;
				height: 60vh;
				.title {
					margin-top: 20px;
					color: #000000;
					font-weight: bold;
					font-size: 16px;
				}
			}
		}
	}
</style>
