<template>
  <!-- 徽章记录 -->
  <div class="badgeRecord">
    <!-- 教师端徽章记录(填写)-->
    <teacher
      ref="teacher"
      :childrenID="childrenID"
      v-show="userType == 2 && !isDetailsFlag"
    ></teacher>
    <!-- 家长端徽章记录 -->
    <parent ref="parent" :childrenID="childrenID" v-show="userType == 3 || isDetailsFlag"></parent>
  </div>
</template>

<script>
import teacher from "./teacher/index.vue";
import parent from "./parent/index.vue";

export default {
  name: "BadgeRecord",
  components: {
    teacher,
    parent,
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      List: [],
      currentIndex: 0, //
      show: true,
      childrenID: "",
	  isDetailsFlag:false,
    };
  },
  mounted() {
	if(this.$route.query.isDetailsFlag){
		this.isDetailsFlag = this.$route.query.isDetailsFlag
	}
    if (this.userType == 2 && !this.isDetailsFlag) {
      this.childrenID = this.$route.query.id;
    } else {
		console.log(this.$refs.parent)
      this.childrenID = this.$refs.parent.childrenID = this.$route.query.id;
      this.$refs.parent._getBadgeList();
    }
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.badgeRecord {
  height: 100%;
}
</style>
