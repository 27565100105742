<template>
	<!-- 徽章记录 -->
	<div class="badgeRecord">
		<!-- 教师端徽章记录-->
		<div class="" style="height: 100%;display: flex;flex-direction: column;">
			<div class="top">
				<div class="content">
					<div class="my-card" v-for="(item,index) in  urlList" :key="index" @click="badgeClick(index)"
						:class="[currentIndex == index? 'active':'']">
						<img :src="item.url" />
					</div>
				</div>
			</div>
			<div class="middle" style="flex: 1;background-color: white;">
				<div class="content">
					<div class="title">
						{{titleList[currentIndex]}}
					</div>
					<!-- 表单 -->
					<van-form @submit="onSubmit" ref="form">
						<van-field v-model="form.EvaluateContent" rows="3" type="textarea" placeholder="请填写您的评价"
							:rules="[{ required: true}]" required />
						<div style="margin-top: 30px;">
							<van-button round block type="info" native-type="submit" :loading='loading' color="#47AFA7">
								保存
							</van-button>
						</div>
					</van-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Form,
		Field,
		Button,
		Popup,
		Toast,
		ActionSheet,
	} from "vant";


	export default {
		name: 'Teacher',
		components: {
			[Toast.name]: Toast,
			[ActionSheet.name]: ActionSheet,
			[Form.name]: Form,
			[Button.name]: Button,
			[Field.name]: Field
		},
		props: {
			childrenID: String
		},
		watch: {
			// 监听徽章切换
			currentIndex(newValue, oldValue) {
				this.form.EvaluateContent = ''
				this.$refs.form.resetValidation()
			}
		},
		data() {
			return {
				userType: window.localStorage.getItem("UserType"),
				urlList: [{
						url: require('../../../../../assets/home/one.png')
					},
					{
						url: require('../../../../../assets/home/two.png')
					},
					{
						url: require('../../../../../assets/home/three.png')
					},
					{
						url: require('../../../../../assets/home/four.png')
					},
					{
						url: require('../../../../../assets/home/five.png')
					}
				],
				currentIndex: 0, //
				//show: true,
				titleList: ['文明小宝贝', '小小艺术家', '小小智多星', '语言小天才', '运动小达人'],
				form: {
					EvaluateContent: ''
				},
				loading: false,
				//childrenID: '',
			}
		},
		methods: {
			// 徽章点击
			badgeClick(index) {
				this.currentIndex = index
				//this.show = true
			},
			// 保存提交
			onSubmit() {
				this.$axios.post('/api/BadgeRecord/add-badgeRecord', {
					childrenID: this.childrenID,
					BadgeRecordType: this.currentIndex + 1,
					EvaluateContent: this.form.EvaluateContent
				}).then(res => {
					if (res.code == 200) {
						this.loading = false
						this.$toast.success(res.msg)
						//this.show = false
						this.form.EvaluateContent = ''
					}
				})
			},
			// 获取徽章列表
			_getBadgeList() {
				this.$axios.get('/api/BadgeRecord/badgeRecord-list', {
					childrenID: this.childrenID
				}).then(res => {
					console.log(res)
				})
			},
			cancel() {
				this.show = false
				this.form.EvaluateContent = ''
				this.$refs.form.resetValidation()
			}
		}
	}
</script>

<style lang="less" scoped>
	.badgeRecord {
		height: 100%;

		.top {
			height: 22%;
			background-image: linear-gradient(#47AFA7, #ffffff);
			display: flex;

			.content {
				display: flex;
				overflow-x: scroll;
				align-items: center;

				.my-card {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 10px;
					padding: 10px 6px;

					img {
						width: 70px;
						height: 70px;
					}
				}

				.active {
					scale: 1.3;
					margin: 0 20px;
				}
			}
		}

		.middle {
			.content {
				padding: 20px;
				height: 60vh;

				.title {
					margin-top: 20px;
					color: #000000;
					font-weight: bold;
					font-size: 16px;
				}
			}
		}
	}
</style>
